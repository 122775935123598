// Frequently Asked Questions
const FAQS = [
  {
    title: "What do I need to provide to set up Clear Factor account?",
    content: `<p>When you come onto the Clear Factor ecosystem for the first time, a Director will need to
      register as a user and provide some information about your business. We use strict Know
      Your Customer (KYC), Know Your Business (KYB), Anti Money Laundering (AML), and
      Open Banking processes to keep the ecosystem a safe and secure place to trade.</p>
      
      <p>You will need to provide access to your business bank account via Open Banking. A few
      banks still do not participate in this scheme. Unfortunately, at this time, if your bank is one of
      those exceptions we will not be able to assist you. For more information, please go to;</p>
      
      <div><a target="_blank" rel="noopener noreferrer" href="https://www.openbanking.org.uk">https://www.openbanking.org.uk</a></div>
      
      <p>KYC checks are carried out on all directors and any shareholders with more than 25%
      shareholding in the company. Copy passport and driving licences may need to be provided if we cannot complete the KYC process online for an individual.</p>
      
      <p>Clear Factor will open an e-money account in the name of your business, through which all
      transactions will be processed. Any payments made to you can then be withdrawn to your
      usual business bank account.</p>
      
      <p>You will be asked to accept the Terms and Conditions for both the e-money account and
      your Clear Factor facility at the time your account is set up.</p>`,
  },
  {
    title: "Why Do You Need My Mobile Number?",
    content: `<p>We use your mobile number to send you a one time passcode (OTP) as part of our secure authentication process when you sign in to your account.</p>`,
  },
  {
    title: "How do I submit an invoice for funding?",
    content: `<p>Once you have set up your Clear Factor account, log into your Clear Factor account and select <u>Submit an Invoice</u> from the SME menu. You will need to upload a soft copy (for example a scan or photo) of the invoice and of the relevant purchase order, if appropriate.</p>
    
    <p>At present, the debtor (your Customer) must be a UK incorporated (limited) business.</p>
    
    <p>The gross invoice value must not be less than £100 or more than £5,000, and must have a due date at least 15 days after the date you submit the invoice.</p>`,
  },
  {
    title: "How long before I receive funds?",
    content: `<p>Once you have submitted an invoice, Clear Factor assesses its suitability using a unique scoring approach using data from Companies House, credit risk agencies, Open Banking and information generated within its own system. Acceptable invoices are rated between 1 and 5, with each rating determining the advance rate (the proportion of the invoice value we will advance) of between 75% and 90.  For example, if you submit an invoice which we rate as 5, we will be able to provide an advance of 90% of the gross value of the invoice.</p>
    
    <p>Clear Factor then emails the debtor advising them that your invoice has been assigned to Clear Factor, requesting their confirmation of the invoice and providing to the debtor bank account details into which the invoice proceeds must be paid.</p>
    
    <p>When the debtor replies, the invoice is passed the auction process and our investors make an offer to you. You can then either accept or decline that offer.  If you accept, advance amount less the initial fee and any transmission costs is placed into the e-money from which you can draw down funds to your business bank account.</p> 
    
    <p>Depending on how long the debtor takes to respond, this process can complete in a matter of minutes.</p>`,    
  },
  {
    title: "How does the auction work?",
    content: `<p>Our investors set the interest rate per annum at which they are prepared to advance the funds. Each investor may set a different rate depending on the rating given to the invoice.  Either the first offer for the full advance amount or the offer at the lowest interest rate if there is more than one for the full amount is sent to you for acceptance. The offer can be viewed via your Clear Factor account.</p>`,
  },
  {
    title: "Do you require any additional security?",
    content: `<p>No, unlike most lenders, we do not take a charge over the business assets nor do we ask for the directors or shareholders to provide personal guarantees so protecting the limited liability of those individuals.</p>`,
  },
  {
    title: "What are your charges?",
    content: `<p>Clear Factor charges are:</p>
    
    <ol>
      <li><div>1.</div> <p> A fee of 2.4% + VAT of the amount initially advanced. This is a one off fee irrespective of the credit period of the invoice.</p></li>
      <li><div>2.</div> <p>A discount change (equivalent to interest) calculated on a daily basis on the amount of the initial advance at the rate specified in the offer you accept, subject to a minimum charge of £1.  This amount is deducted from any balance due to you upon payment by the debtor.</p></li>
      <li><div>3.</div> <p>Bank account transaction charges of 15p for each credit entry to the e-money account and 50p for each Faster Payment or BACS payment from your e-money account to your bank account.</p></li>
    </ol>
    
    <p>More details are provided in the Clear Factor Terms and Conditions document.</p>`,
  },
  {
    title: "Why do you need to contact the debtor?",
    content: `<p>As part of our process we need to advise the debtor that the business has assigned its rights to Clear Factor.  We also need to take steps to ensure there are no discrepancies in the invoice, so protecting our investors and the business.</p>`,
  },
  {
    title: "What happens when the debtor pays the invoice?",
    content: `<p>The debtor pays the funds into an account specified by Clear Factor and we then repay the advance amount to the investor together with the interest they are due and credit the balance less any transmission cots to your e-money account.</p>`,
  },
  {
    title: "Why does the debtor need to pay into a different bank account?",
    content: `<p>As we have made an initial advance to the business and as the invoice has been assigned to Clear Factor, the proceeds of the invoice are due to Clear Factor. This again protects all parties.</p>`,
  },
  {
    title: "What happens if the debtor pays me directly?",
    content: `<p>You must immediately pay the amount received to Clear Factor to the following bank account and forward an email advising us of the details to <a href="mailto:remittances@clearfactor.io">remittances@clearfactor.io</a></p>
    
    <ul>
      <li>Sort code: 04-00-85</li>
      <li>Account number: 06321801</li>
      <li>Account name: Clear Factor Limited</li>
    </ul>  `,
  },
  {
    title: "What happens if the debtor does not pay?",
    content: `<p>Clear Factor will contact the debtor on your behalf to request payment and establish reasons for non-payment.  We may commence legal action with your consent.  We may also request you repay us the advance amount together with any discount or costs due (this is known as a Repurchase Notice).</p>`,
  },
  {
    title: "Do I need to send any remittance advices to you?",
    content: `<p>Yes, if you receive a remittance advice from the debtor for any invoice we have financed, please send it on to <a href="mailto:remittances@clearfactor.io">remittances@clearfactor.io</a></p>`,
  },
  {
    title: "Why do you need open banking access?",
    content: `<p>We use your business open banking data as part of our identification and verification process, and also as part of our decision making.  As this data is up to date it allows us to provide you with the most appropriate offer rather than rely only upon historic and limited data available in the public domain.</p>`,
  },
  {
    title: "Can I add someone else as a user of the Clear Factor account?",
    content: `<p>Yes. Once you have completed registration of the business, log into your Clear Factor account and select <u>My Team</u> from the SME menu.  Then select <u>Add Member</u> and provide the person’s details. Clear Factor will then send an email to them with instructions.</p>
    
    <p>You can set the person as a Super User giving them full rights, or as a User which excludes the right to make payments from the e-money account.</p>`,
  },
  {
    title: "How do I contact you if I have a query?",
    content: `<p>You may contact us by email at <a href="mailto:support@clearfactor.io">support@clearfactor.io</a>.</p>`,
  },
]

export default FAQS
