import React, { useState } from "react"
import { Link } from "gatsby"

// FAQ store
import "../store/faqs"
import FAQS from "../store/faqs"

// components
import Layout from "../components/layout"
import Accordion from "../components/accordion"

const FAQ = () => {
  const [expanded, setExpanded] = useState(0)

  return (
    <Layout
      title="Frequently Asked Questions"
      description="Here are some questions our customers ask."
      containerClass="bg-blue-200"
    >
      <section className="site-container pt-32 pb-32 bg-blue-200">
        <div className="text-center space-y-6 max-w-2xl 2xl:max-w-4xl mx-auto">
          <h1 className="type-8xl text-blue-800 font-semibold">
            Frequently Asked Questions
          </h1>
          <p className="type-lg text-blue-300 font-light">
            Here are some questions our customers ask. If there’s anything we
            haven’t covered here, check don't hesitate to{" "}
            <span className="underline font-semibold">
              <Link to="/">contact us</Link>
            </span>
          </p>
        </div>
        {/* questions - accordions */}
        <div className="space-y-10 max-w-5xl mx-auto pt-20">
          {FAQS.map((e, i) => (
            <Accordion
              key={i}
              id={i}
              title={e.title}
              content={e.content}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default FAQ
