import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import ShadowBox from "./shadow-box"

const Accordion = ({ title, id, content, expanded, setExpanded }) => {
  const isOpen = id === expanded
  const ref = useRef()

  useEffect(() => {
    gsap.to(ref.current.children[1], {
      height: isOpen ? "auto" : 0,
    })
    gsap.to(ref.current.children[0].children[1], {
      rotate: isOpen ? 90 : 0,
      duration: 0.5,
    })
  }, [isOpen])

  return (
    <ShadowBox>
      <div ref={ref}>
        <button
          onClick={() => setExpanded(id === expanded ? -1 : id)}
          className="w-full focus:outline-none flex justify-between items-center py-5 px-8 cursor-pointer text-left space-x-2 sm:space-x-0"
        >
          <h3 className="type-2xl text-blue-300 font-light">{title}</h3>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22.249"
              height="13.246"
              viewBox="0 0 22.249 13.246"
            >
              <path
                id="Path_5"
                data-name="Path 5"
                d="M6323.533,483.533,6333.6,493.6l10.064-10.064"
                transform="translate(-6322.473 -482.473)"
                fill="none"
                stroke="#2357a2"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
        <div className="accordion-content overflow-hidden h-1">
          <div
            className="pt-6 pb-10 px-8 type-base text-grey-500 space-y-6"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </ShadowBox>
  )
}

export default Accordion
